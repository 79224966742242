<template>
  <div id="main"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  mounted() {
    // this.initChart();
  },
  props: {
    chartData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    chartData: {
      handler() {
        this.initChart();
      },
      deep: true,
    },
  },
  methods: {
    formatName(name) {
      switch (name) {
        case "wechat":
          return "微信";
        case "weibo":
          return "微博";
        case "douyin":
          return "抖音";
        case "xiaohongshu":
          return "小红书";
        case "bilibili":
          return "哔哩哔哩";
      }
    },
    initChart() {
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            console.log(params);
            let str = `<p>${this.formatName(params[0].name)}</p>`;
            str += `<p>${params[0].marker}曝光UV：${this.sliceDecimal(
              params[0].value
            )}</p>`;
            str += `<p>${params[1].marker}曝光量：${this.sliceDecimal(
              params[1].value
            )}</p>`;
            return str;
          },
        },
        grid: {
          left: "7%",
          right: "8%",
          bottom: "1%",
          top: "1%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          show: false,
        },
        yAxis: {
          type: "category",
          data: ["bilibili", "xiaohongshu", "douyin", "weibo", "wechat"],
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            align: "right",
            margin: 14,
            formatter: (value) => `{${value}| }`,
            rich: {
              wechat: {
                fontSize: 21,
                width: 21,
                height: 21,
                backgroundColor: {
                  image: require("./platformImg/wechat.png"),
                },
              },
              weibo: {
                fontSize: 21,
                width: 21,
                height: 21,
                backgroundColor: {
                  image: require("./platformImg/weibo.png"),
                },
              },
              douyin: {
                fontSize: 21,
                width: 21,
                height: 21,
                backgroundColor: {
                  image: require("./platformImg/douyin.png"),
                },
              },
              xiaohongshu: {
                fontSize: 21,
                width: 21,
                height: 21,
                backgroundColor: {
                  image: require("./platformImg/xiaohongshu.png"),
                },
              },
              bilibili: {
                fontSize: 21,
                width: 21,
                height: 21,
                backgroundColor: {
                  image: require("./platformImg/bilibili.png"),
                },
              },
              kuaishou: {
                fontSize: 21,
                width: 21,
                height: 21,
                backgroundColor: {
                  image: require("./platformImg/kuaishou.png"),
                },
              },
            },
          },
        },
        series: [
          {
            name: "uv",
            type: "bar",
            data: this.chartData.uv,
            label: {
              show: true,
              position: "right",
              formatter: (params) => {
                console.log(params);
                if (params.value > 1000 && params.value < 1000000) {
                  return (params.value / 1000).toFixed(0) + "K";
                } else if (
                  params.value >= 1000000 &&
                  params.value < 1000000000
                ) {
                  return (params.value / 1000000).toFixed(0) + "M";
                } else if (params.value > 1000000000) {
                  return (params.value / 1000000000).toFixed(0) + "B";
                } else {
                  return params.value;
                }
              },
            },
          },
          {
            name: "exposure",
            type: "bar",
            data: this.chartData.exposure,
            label: {
              show: true,
              position: "right",
              formatter: (params) => {
                console.log(params);
                if (params.value > 1000 && params.value < 1000000) {
                  return (params.value / 1000).toFixed(0) + "K";
                } else if (
                  params.value >= 1000000 &&
                  params.value < 1000000000
                ) {
                  return (params.value / 1000000).toFixed(0) + "M";
                } else if (params.value > 1000000000) {
                  return (params.value / 1000000000).toFixed(0) + "B";
                } else {
                  return params.value;
                }
              },
            },
          },
        ],
        color: ["#F37092", "#38A1FF"],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
#main {
  width: 100px;
  height: 200px;
}
</style>
