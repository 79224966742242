<template>
  <div class="publicStyle distribute">
    <p class="publicTitleStyle">{{ title }}
      <div>
        <svg-icon
            icon-class="downloadIcon"
            class-name="downloadIcon"
        ></svg-icon>
        <el-button
            :loading="downloadGeneralViewLoading"
            class="noborderBtn"
            type="text"
            style="margin-right:0;color:#000;"
            @click="downloadGeneralView"
        >
          下载
        </el-button>
      </div>
      </p>
    <div style="text-align: right; padding-right: 20px">

    </div>
    <div class="distributeTable">
      <el-table
        v-loading="distributeLoading"
        ref="treeTable"
        :row-class-name="distributeRowStyleFunc"
        :data="tableData"
        :tree-props="{ children: 'details' }"
        row-key="id"
        @expand-change="expandChange"
      >
        <el-table-column
          prop="name"
          align="center"
          show-overflow-tooltip
          :width="isHasTotal ? '140' : '200'"
          :label="headerWord"
        >
        </el-table-column>
        <el-table-column
          class="wechat"
          prop="exposure"
          align="center"
          label="曝光量"
        >
          <template #header>
            <el-tooltip class="item" effect="dark" placement="top">
              <template #content>全部发帖的去水曝光量</template>
              <div>曝光量</div>
            </el-tooltip>
          </template>
          <template #default="scoped">
            {{
              distribute.radio === "percent"
                ? scoped.row.exposure === "--"
                  ? "--"
                  : sliceDecimal(scoped.row.exposure)
                : sliceDecimal(scoped.row.exposure)
            }}
          </template>
        </el-table-column>
        <el-table-column
          class="wechat"
          prop="engagementQs"
          align="center"
          label="互动量"
        >
          <template #header>
            <el-tooltip class="item" effect="dark" placement="top">
              <template #content>全部发帖的去水互动量</template>
              <div>互动量</div>
            </el-tooltip>
          </template>
          <template #default="scoped">
            {{
              distribute.radio === "percent"
                ? scoped.row.exposure === "--"
                  ? "--"
                  : sliceDecimal(scoped.row.engagementQs)
                : sliceDecimal(scoped.row.engagementQs)
            }}
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          prop="pgcNum"
          align="center"
          label="PGC发帖数量"
        >
          <template #header>
            <div>
              <p>PGC</p>
              <p>发帖数量</p>
            </div>
          </template>
          <template #default="scoped">
            {{
              distribute.radio === "percent"
                ? scoped.row.pgcNum === "--"
                  ? "--"
                  : sliceDecimal(scoped.row.pgcNum)
                : sliceDecimal(scoped.row.pgcNum)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="ugcNum" align="center" label="UGC发帖数量">
          <template #header>
            <div>
              <p>UGC</p>
              <p>发帖数量</p>
            </div>
          </template>
          <template #default="scoped">
            {{
              distribute.radio === "percent"
                ? scoped.row.ugcNum === "--"
                  ? "--"
                  : sliceDecimal(scoped.row.ugcNum)
                : sliceDecimal(scoped.row.ugcNum)
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="pgcExposurePct"
          align="center"
          label="PGC曝光占比"
          width="90"
        >
          <!-- PGC发帖对应的去水曝光量占全部发帖去水曝光量的比例 -->
          <template #header>
            <div>
              <el-tooltip class="item" effect="dark" placement="top">
                <template #content
                  >PGC发帖对应的去水曝光量占全部发帖去水曝光量的比例</template
                >
                <div>
                  <p>PGC</p>
                  <p>曝光占比</p>
                </div>
              </el-tooltip>
            </div>
          </template>
          <template #default="scoped">
            {{
              distribute.radio === "percent"
                ? scoped.row.pgcExposurePct === "--"
                  ? "--"
                  : sliceDecimal(scoped.row.pgcExposurePct)
                : sliceDecimal(scoped.row.pgcExposurePct)
            }}
          </template>
        </el-table-column>
        <!-- PGC发帖去水曝光量 -->
        <el-table-column prop="pgcExposure" align="center" label="PGC曝光量">
          <template #header>
            <el-tooltip class="item" effect="dark" placement="top">
              <template #content>PGC发帖去水曝光量</template>
              <div>
                <p>PGC</p>
                <p>曝光量</p>
              </div>
            </el-tooltip>
          </template>
          <template #default="scoped">
            {{
              distribute.radio === "percent"
                ? scoped.row.pgcExposure === "--"
                  ? "--"
                  : sliceDecimal(scoped.row.pgcExposure)
                : sliceDecimal(scoped.row.pgcExposure)
            }}
          </template>
        </el-table-column>
        <!-- PGC发帖去水互动量 -->
        <el-table-column
          prop="pgcEngagementQs"
          align="center"
          label="PGC互动量"
        >
          <template #header>
            <el-tooltip class="item" effect="dark" placement="top">
              <template #content>PGC发帖去水互动量</template>
              <div>
                <p>PGC</p>
                <p>互动量</p>
              </div>
            </el-tooltip>
          </template>
          <template #default="scoped">
            {{
              distribute.radio === "percent"
                ? scoped.row.pgcEngagementQs === "--"
                  ? "--"
                  : sliceDecimal(scoped.row.pgcEngagementQs)
                : sliceDecimal(scoped.row.pgcEngagementQs)
            }}
          </template>
        </el-table-column>
        <!-- PGC发帖对应的KOL刊例价加总，并由算法校验计算得出 -->
        <el-table-column prop="cost" align="center" label="投放费用">
          <template #header>
            <el-tooltip class="item" effect="dark" placement="top">
              <template #content
                >PGC发帖对应的KOL刊例价加总，并由算法校验计算得出</template
              >
              <div>投放费用</div>
            </el-tooltip>
          </template>
          <template #default="scoped">
            {{
              distribute.radio === "percent"
                ? scoped.row.cost === "--"
                  ? "--"
                  : "¥" + sliceDecimal(scoped.row.cost)
                : "¥" + sliceDecimal(scoped.row.cost)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="cpm" align="center" label="CPM">
          <template #header>
            <el-tooltip class="item" effect="dark" placement="top">
              <template #content
                >PGC发帖的千次曝光成本，基于去水曝光量计算</template
              >
              <div>CPM</div>
            </el-tooltip>
          </template>
          <template #default="scoped">
            {{
              distribute.radio === "percent"
                ? scoped.row.cpm === "--"
                  ? "--"
                  : "¥" + scoped.row.cpm
                : scoped.row.cpm
            }}
          </template>
        </el-table-column>
        <el-table-column prop="cpe" align="center" label="CPE" width="80">
          <template #header>
            <el-tooltip class="item" effect="dark" placement="top">
              <template #content
                >PGC发帖的单次互动成本，基于去水互动量计算</template
              >
              <div>CPE</div>
            </el-tooltip>
          </template>
          <template #default="scoped">
            {{
              distribute.radio === "percent"
                ? scoped.row.cpe === "--"
                  ? "--"
                  : "¥" + scoped.row.cpe
                : scoped.row.cpe
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { downloadGeneralView } from "@/api/sov/sovBrand";
import { getProductDistribution } from "@/api/sov/sovProduct";
import { getCategoryDistribution } from "@/api/sov/sovCategory";
import { getBrandOverview } from "@/api/sov/sovBrand";
import { mapState } from "vuex";

export default {
  data() {
    return {
      distributeLoading: true,
      distribute: {
        radio: "percent",
      },
      showTier: false,
      tableData: [],
      expansion: [false],
      downloadGeneralViewLoading: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    isHasTotal: {
      type: Boolean,
      default: true,
    },
    headerWordType: {
      type: String,
      default: "0",
      // 0：brand
      // 1：product
      // 2：productCompare
      // 3: category
      // 4: categoryCompare
    },
  },
  computed: {
    ...mapState(["brandSeg"]),
    headerWord() {
      if (this.headerWordType === "0") {
        return "品牌名称";
      } else if (this.headerWordType === "1") {
        return "产品";
      } else if (this.headerWordType === "2") {
        return "TOP品类";
      } else if (this.headerWordType === "3") {
        return "TOP5品牌";
      } else if (this.headerWordType === "4") {
        return "品类";
      } else {
        return "TOP品类";
      }
    },
    id() {
      console.log(this.$route.query.brandId);
      console.log(this.atob(this.$route.query.brandId));
      console.log(this.headerWordType);
      if (JSON.stringify(this.$route.query) === "{}") {
        return "";
      } else if (this.headerWordType === "0") {
        console.log(this.atob(this.$route.query.brandId));
        return this.atob(this.$route.query.brandId);
      } else if (this.headerWordType === "1") {
        return this.atob(this.$route.query.productId);
      } else if (this.headerWordType === "2") {
        return this.atob(this.$route.query.products);
      } else if (this.headerWordType === "3") {
        return this.atob(this.$route.query.categoryId);
      } else if (this.headerWordType === "4") {
        return this.atob(this.$route.query.categories);
      } else {
        return this.atob(this.$route.query.brandId);
      }
    },
    timeRange() {
      if (this.$route.query.duration) {
        return JSON.parse(this.$route.query.duration);
      } else {
        return [];
      }
    },
    isAllExpand() {
      return !this.expansion.includes(false);
    },
  },
  mounted() {
    this.getDistribution();
  },
  methods: {
    downloadGeneralView() {
      const fileName = `${decodeURI(
        this.$route.query.brand
      )}_品牌声量概览.xlsx`;
      const params = {
        endMonth: this.timeRange[1],
        startMonth: this.timeRange[0],
        id: this.id,
        seg: this.brandSeg,
      };
      this.downloadGeneralViewLoading = true;
      this.$message({
        type: "info",
        message: "文件正在解析，请耐心等待",
      });
      downloadGeneralView(params, fileName)
        .then(() => {
          this.$message({
            type: "success",
            message: "文件正在下载，请耐心等待",
          });
          this.downloadGeneralViewLoading = false;
        })
        .catch(() => {
          this.$message.error("文件下载失败,请联系管理员!");
        });
    },
    handleExpansion() {
      const expanded = this.expansion.includes(false);
      this.tableData.forEach((item) => {
        this.$refs.treeTable.toggleRowExpansion(item, expanded);
      });
    },
    expandChange(row, expanded) {
      console.log(row, expanded);
      // let doms = document.querySelectorAll(".el-table__placeholder");
      // let that = this;
      // doms.forEach((v) => {
      //   // v.classList.add("el-icon-warning-outline");
      //   v.onclick = function (e) {
      //     that.showTier = true;
      //     e.stopPropagation();
      //   };
      // });
      const index = row.id - 1;
      this.expansion[index] = expanded;
    },
    distributeRowStyleFunc({ rowIndex }) {
      console.log(rowIndex);
      if (!this.isHasTotal) {
        return "otherStyle";
      } else if (rowIndex === 0) {
        return "firstRowStyle";
      } else {
        return "otherStyle";
      }
    },
    async getDistribution() {
      console.log(this.headerWordType);
      this.distributeLoading = true;
      let res;
      if (this.headerWordType === "0") {
        console.log(22222);
        res = await getBrandOverview({
          // duration: this.timeRange[0],
          // duration: this.timeRange,
          id: this.id,
          endMonth: this.timeRange[1],
          startMonth: this.timeRange[0],
          // aggMonth: this.timeRange[1],
        });
        // res = await getBrandDistribution({
        //   duration: this.timeRange,
        //   id: this.id,
        //   type: this.distribute.radio,
        // });
      }
      if (this.headerWordType === "1" || this.headerWordType === "2") {
        res = await getProductDistribution({
          duration: this.timeRange,
          id: this.id,
          type: this.distribute.radio,
        });
      }
      if (this.headerWordType === "3" || this.headerWordType === "4") {
        res = await getCategoryDistribution({
          duration: this.timeRange,
          id: this.id,
          type: this.distribute.radio,
        });
      }
      let { code, data } = res;
      console.log(code, data);
      if (code === 0) {
        this.formatNull(data);
        // 构建展开数据结构
        // data.forEach((v) => {
        //   if (v.name === "total") {
        //     v.name = "总计";
        //     delete v.details;
        //   }
        // });
        // 构建数据结构
        // let temp = data[0];
        // data.splice(0, 1);
        // temp.details = [...data];
        // data = [temp];
        // data.forEach((v, i) => {
        //   v.id = i + 1;
        //   if (v.details && v.details.length !== 0 && v.name !== "total") {
        //     v.details.forEach((k) => {
        //       k.name = this.platformToZn(k.name);
        //       k.id = Math.random() * 2 + 1;
        //     });
        //     this.expansion[i] = false;
        //   } else {
        //     this.expansion[i] = null;
        //   }
        //   v.details && v.details.length
        //     ? (this.expansion[i] = false)
        //     : (this.expansion[i] = null);
        // });
        data.forEach((v) => {
          v.name = this.platformToZn(v.name);
        });
        this.tableData = data;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
      this.distributeLoading = false;
    },
  },
  watch: {
    timeRange(val) {
      console.log(val);
      if (val.length !== 0) this.getDistribution();
    },
  },
};
</script>

<style lang="scss" scoped>
.publicStyle {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.downBtn {
  // margin-right: 20px;
  background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  padding: 0 10px 1px;

  .downIconClass {
    font-size: 16px;
    margin-left: 10px;
  }
}

.distribute {
  margin-bottom: 20px;

  .publicTitleStyle {
    padding:20px;
    padding-bottom:0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    color: #4f4f4f;
  }

  .spandAll {
    background-color: #f4f3f3;
    color: #8c98a5;
    border-radius: 4px;
    transition: transform 0.2s ease-in-out;

    .rotate {
      transform: rotate(-90deg);
    }
  }

  .isExpandClass {
    background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
    color: #fff;
  }

  .distributeTable {
    .paddingClass {
      display: flex;
      height: 40px;
      line-height: 40px;

      .spanBox {
        flex: 1;
        display: flex;
        justify-content: space-around;
      }

      span {
        display: inline-block;
      }
    }
  }
}

.imgClass {
  width: 100%;
  height: 100%;
  background-size: contain;
}
</style>

<style lang="scss">
.distribute {
  .distributeTable
    .el-table
    .el-table__header-wrapper
    .el-table__header
    thead
    > tr
    > th
    > .cell {
    color: #8c98a5;
  }

  .rowStyle {
    td div {
      font-size: 14px;
      color: #34355b;
      font-weight: 500;
    }
  }

  .firstRowStyle {
    background-color: #f9fcff;

    td div {
      font-size: 16px;
      color: #393a4d;
      font-weight: 500;
    }
  }

  .otherStyle {
    td div {
      font-size: 14px;
      color: #34355b;
      font-weight: 400;
    }
  }

  .el-radio__input.is-checked .el-radio__inner {
    background-color: #fff;
    border-color: #dda634;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #dda634;
  }

  .el-radio__inner:hover {
    border-color: #dda634;
  }

  .el-radio__inner {
    width: 20px;
    height: 20px;
    background-color: #fff;
  }

  .el-radio__inner::after {
    width: 10px;
    height: 10px;
    background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
  }

  .el-radio {
    margin-right: 20px;
  }

  .distributeTable
    .el-table
    .el-table__header-wrapper
    .el-table__header
    thead
    > tr
    > th
    > .cell {
    color: #8c98a5;
  }

  .el-table__expanded-cell[class*="cell"] {
    background-color: #f9fcff;
    padding: 0;
    padding-left: 90px;
  }

  thead .is-left .cell {
    margin-left: 20px;
  }

  tbody .firstRowStyle .is-left .cell {
    margin-left: 20px;
  }

  .el-table__placeholder {
    z-index: 1000;
    cursor: pointer;
  }
}
</style>
