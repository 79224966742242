<template>
  <div class="contentWrap">
    <div class="publicStyle">
      <p class="publicTitleStyle">{{ title }}
        <div class="headBox">
          <div class="segClass">
            <span
                :style="{
              fontSize:'14px',
              marginRight: '10px',
            }"
            >目标人群</span
            >
            <el-select
                v-model="segValue"
                size="mini"
                class="m-2 autoWidth"
                @change="changeSeg"
                placeholder="请选择人群"
            >
              <template #prefix>
                {{ crowdListComputed.filter(i => i.key === segValue)[0].name }}
              </template>
              <el-option
                  v-for="item in crowdListComputed"
                  :key="item.key"
                  :label="item.name"
                  :value="item.key"
              />
            </el-select>
          </div>
          <span class="searchText">查找产品/品类关键词</span>
          <el-input
              @keyup.enter="handleSearch"
              v-model="searchInput"
              placeholder="请输入内容"
          ></el-input>
          <el-button
              size="mini"
              @click="searchBtn"
              class="btnPublic"
              style="width: 64px"
          >搜索
          </el-button>
          <div style="margin-left:10px;">
            <svg-icon
                icon-class="downloadIcon"
                class-name="downloadIcon"
            ></svg-icon>
            <el-button
                :loading="btnLoading"
                class="noborderBtn"
                type="text"
                style="margin-right:0;color:#000;"
                @click="downContent"
            >
              下载
            </el-button>
          </div>
        </div>
      </p>

      <div class="kolContentTable">
        <el-table
          height="650px"
          :data="tableData"
          v-loading="loading"
          style="max-height: 650px; overflow: auto"
        >
          <el-table-column
            prop="brandName"
            align="center"
            width="100"
            label="品牌"
            fixed
          >
          </el-table-column>
          <el-table-column
            prop="productName"
            align="center"
            show-overflow-tooltip
            label="产品"
            width="160"
            fixed
          >
            <template #default="{ row }">
              {{
                row.productName === null
                  ? "--"
                  : `${row.productName.replace(/,/g, "\n")}`
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="kolName"
            align="center"
            show-overflow-tooltip
            width="100"
            label="KOL名称"
            fixed
          >
          </el-table-column>
          <el-table-column
            prop="platform"
            align="center"
            width="130"
            label="平台"
            fixed
          >
            <template #header>
              <el-select v-model="selPlatform" placeholder="请选择">
                <el-option
                  v-for="item in platform"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
            <template #default="{ row }">
              {{ platformToZn(row.platform) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="tier"
            align="center"
            show-overflow-tooltip
            label="KOL级别"
          >
            <template #default="{ row }">
<!--              {{-->
<!--                row.tier === 0-->
<!--                  ? "KOC"-->
<!--                  : row.tier === 1-->
<!--                  ? "Tier1"-->
<!--                  : row.tier === 2-->
<!--                  ? "Tier2"-->
<!--                  : row.tier === 3-->
<!--                  ? "Tier3"-->
<!--                  : "&#45;&#45;"-->
<!--              }}-->
              {{row.tierStr}}
            </template>
          </el-table-column>
          <el-table-column
            prop="kolFans"
            align="center"
            width="130"
            label="粉丝量"
          >
            <template #default="{ row }">
              {{ sliceDecimal(row.kolFans) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="taRatioPct"
            align="center"
            show-overflow-tooltip
            label="TA浓度%"
            width="100"
          >
            <template #default="{ row }">
              {{ row.taRatioPct === null ? "--" : row.taRatioPct }}
            </template>
          </el-table-column>
          <el-table-column
            width="150"
            prop="postDate"
            align="center"
            label="发文日期"
          >
          </el-table-column>
          <el-table-column
            prop="link"
            align="center"
            show-overflow-tooltip
            width="200"
            label="文章链接"
          >
            <template #default="{ row }">
              <a
                v-if="row.title"
                target="_blank"
                style="color: #51a6f0"
                :href="row.link"
                >{{ row.title }}</a
              >
              <a
                target="_blank"
                style="color: #51a6f0"
                :href="row.link"
                v-else
                >{{ row.link === null ? "--" : row.link }}</a
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="exposure"
            align="center"
            width="130"
            label="阅读量"
          >
            <template #default="{ row }">
              {{ sliceDecimal(row.exposure) }}
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            prop="engagement"
            align="center"
            label="互动量"
          >
            <template #default="{ row }">
              {{ sliceDecimal(row.engagement) }}
            </template>
          </el-table-column>
          <el-table-column prop="waterPct" align="center" label="水量%">
            <template #default="{ row }">
              {{ row.waterPct === null ? "--" : row.waterPct }}
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            prop="price"
            align="center"
            label="投放费用"
          >
            <template #default="{ row }">
              {{ row.price === null ? "--" : "￥" + sliceDecimal(row.price) }}
            </template>
          </el-table-column>
          <el-table-column prop="cpm" align="center" label="CPM">
            <template #default="{ row }">
              {{ row.cpm === null ? "--" : "￥" + sliceDecimal(row.cpm) }}
            </template>
          </el-table-column>
          <el-table-column prop="cpe" align="center" label="CPE">
            <template #default="{ row }">
              {{ row.cpe === null ? "--" : "￥" + sliceDecimal(row.cpe) }}
            </template>
          </el-table-column>
          <el-table-column prop="taCpm" align="center" label="TA CPM">
            <template #default="{ row }">
              {{ row.taCpm === null ? "--" : "￥" + sliceDecimal(row.taCpm) }}
            </template>
          </el-table-column>
          <el-table-column prop="taCpe" align="center" label="TA CPE">
            <template #default="{ row }">
              {{ row.taCpe === null ? "--" : "￥" + row.taCpe }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import storage from "@/utils/storage";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      brandRadio: "",
      searchInput: "",
      platform: [
        {
          label: "全平台",
          value: "all",
        },
        {
          label: "微信",
          value: "wechat",
        },
        {
          label: "微博",
          value: "weibo",
        },
        {
          label: "抖音",
          value: "douyin",
        },
        {
          label: "小红书",
          value: "xiaohongshu",
        },
        {
          label: "哔哩哔哩",
          value: "bilibili",
        },
      ],
      selPlatform: "all",
      btnLoading: false,
      segValue: "32_1",
    };
  },
  mounted() {
    let doms = document.querySelector(".contentWrap .el-table__body-wrapper");
    doms.addEventListener("scroll", this.func(this.scrollHandle, 1000));
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    brandTab: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fileDownFlag: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapMutations({
      setBrandSeg: "SET_BRANDSEG",
    }),
    changeSeg(val) {
      this.setBrandSeg(val);
    },
    formatCrowdList(list = []) {
      let tempList = [];
      list.forEach((v) => {
        if (v.key !== null) {
          tempList.push(v);
        }
      });
      return tempList;
    },
    func(func, delay) {
      var timer = null;
      return () => {
        var args = arguments;
        if (!timer) {
          timer = setTimeout(function () {
            func.apply(this, args);
            timer = null;
          }, delay);
        }
      };
    },
    scrollHandle() {
      console.log(
        document.querySelector(".contentWrap .el-table__body-wrapper").scrollTop
      );
      console.log(
        document.querySelector(".contentWrap .el-table__body-wrapper")
          .scrollHeight
      );
      let timeOut = null;
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        if (
          document.querySelector(".contentWrap .el-table__body-wrapper")
            .scrollTop +
            620 >
          document.querySelector(".contentWrap .el-table__body-wrapper")
            .scrollHeight
        ) {
          this.$emit("loadMoreData");
        }
      }, 400);
    },
    handleSearch() {
      this.$emit("handleSearch", this.searchInput);
    },
    searchBtn() {
      this.$emit("searchBtn", this.searchInput);
    },
    downContent() {
      this.btnLoading = true;
      this.$message({
        type: "info",
        message: "正在解析，请耐心等待!",
      });
      this.$emit("downContent", {
        platform: this.selPlatform,
        searchWord: this.searchInput,
      });
    },
  },
  computed: {
    ...mapState(["brandSeg"]),
    crowdListComputed() {
      return this.formatCrowdList(storage.getItem("corwdListStorage"));
    },
  },
  watch: {
    brandSeg(val) {
      this.segValue = val;
    },
    brandTab: {
      handler(val) {
        this.brandRadio = val;
      },
      immediate: true,
    },
    selPlatform() {
      this.$emit("setSelPlatform", this.selPlatform);
    },
    fileDownFlag(val) {
      if (val) {
        this.btnLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.publicStyle {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.btnPublic {
  background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
  border-radius: 4px;
  color: #fff;
}

.publicTitleStyle {
  padding-top: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  color: #4f4f4f;
}

.headBox {
  height: 40px;
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .segClass {
    height: 40px;
    line-height: 40px;
    margin-right: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .searchText {
    font-size: 14px;
    color: #11263c;
  }

  .btnDown {
    margin-left: 30px;
    padding: 6px 8px;

    .downIconClass {
      margin-left: 10px;
      font-size: 16px;
    }
  }

  .el-input {
    width: 123px;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;
    margin-left: 20px;
    margin-right: 10px;
  }
}
</style>

<style lang="scss">
.contentWrap {
  .el-table .cell.el-tooltip {
    white-space: pre;
  }
  .el-radio__input.is-checked .el-radio__inner {
    background-color: #fff;
    border-color: #dda634;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #dda634;
  }

  .el-radio__inner:hover {
    border-color: #dda634;
  }

  .el-radio__inner {
    width: 20px;
    height: 20px;
    background-color: #fff;
  }

  .el-radio__inner::after {
    width: 10px;
    height: 10px;
    background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
  }

  .el-radio {
    margin-right: 20px;
  }

  .distributeTable
    .el-table
    .el-table__header-wrapper
    .el-table__header
    thead
    > tr
    > th
    > .cell {
    color: #8c98a5;
  }

  .el-table__expanded-cell[class*="cell"] {
    background-color: #f9fcff;
    padding: 0;
    padding-left: 90px;
  }

  .is-active {
    .el-radio-button__inner {
      background-color: #e09a29 !important;
      border-color: #e09a29 !important;
      color: #fff !important;
    }
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    box-shadow: -1px 0 0 0 #e09a29;
  }

  .el-radio-button {
    .el-radio-button__inner {
      background-color: #f8f9fe;
      color: #979797;
    }
  }

  .el-input {
    .el-input__inner {
      height: 100%;
    }
  }

  .kolContentTable {
    margin-top: 10px;

    .el-input--suffix .el-input__inner {
      height: 30px;
    }

    .el-input__suffix {
      top: 5px;
    }

    .el-select .el-input__suffix {
      top: -4px;
    }

    .el-input--suffix .el-input__icon {
      line-height: 30px;
    }
  }

  .segClass .el-input.el-input--mini.el-input--suffix {
    height: 28px;
  }
}
</style>
