<template>
  <div v-loading="rateOfFlow" class="publicStyle">
    <div class="publicTitleStyle monthRadioButton">
      {{ title }}
      <div style="float: right; margin-right: 10px">
        <span
          style="
            float: left;
            font-size: 14px;
            font-style: italic;
            margin-right: 12px;
            margin-top: 4px;
          "
          >* {{ timeRange[1] }}</span
        >
        <el-radio-group
          v-model="aggType"
          size="mini"
          :class="{ cancelMargin: !isShowCategory }"
          style="float: right"
        >
          <el-radio-button label="1month">近1个月</el-radio-button>
          <el-radio-button label="3months">近3个月</el-radio-button>
          <el-radio-button label="6months">近6个月</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="content">
      <div class="leftbox">
        <div class="imgWrapper" style="zoom: 0.5">
          <canvas id="canvas"></canvas>
          <img class="imgClass" :src="logo" alt="" />
        </div>
        <!-- <el-tooltip effect="dark" content="EC搜索UV / 曝光UV" placement="top">
          <svg-icon class-name="dashboard_tooltip" class="hover"></svg-icon>
        </el-tooltip> -->
        <div class="showNum">
          <div>
            <p>
              <span
                style="
                  display: inline-block;
                  width: 32px;
                  height: 9px;
                  background-color: #38A1FF;
                "
              ></span>
              <span
                class="wordClass"
                style="font-size: 30px; color: #3648A4; margin-left: 10px"
                >{{
                  exposure > 1000000000
                    ? sliceDecimal((exposure / 1000000000).toFixed(0)) + "B"
                    : exposure > 1000000
                    ? sliceDecimal((exposure / 1000000).toFixed(0)) + "M"
                    : sliceDecimal(exposure)
                }}</span
              >
            </p>
            <span class="textClass">曝光量</span>
          </div>
          <div style="margin: 7px 0">
            <p>
              <span
                style="
                  display: inline-block;
                  width: 32px;
                  height: 9px;
                  background-color: #f37092;
                "
              ></span>
              <span
                class="wordClass"
                style="font-size: 30px; color: #3648A4; margin-left: 10px"
                >{{ sliceDecimal(exposureUv) }}</span
              >
            </p>
            <span class="textClass">曝光UV</span>
          </div>
          <!-- <div>
            <p>
              <span
                style="
                  display: inline-block;
                  width: 32px;
                  height: 9px;
                  background-color: #feec65;
                "
              ></span>
              <span
                class="wordClass"
                style="font-size: 30px; color: '#3648A4'; margin-left: 10px"
                >{{ searchUv }}</span
              >
            </p>
            <span class="textClass">EC搜索UV</span>
          </div> -->
        </div>
      </div>
      <flowChart class="chartClass" :chartData="chartData"></flowChart>
      <div class="tableClass">
        <div class="radioGroupClass">
          <el-radio-group
            v-model="field"
            size="mini"
            :class="{ cancelMargin: !isShowCategory }"
          >
            <el-radio-button label="pgc_num">近1个月</el-radio-button>
            <el-radio-button label="cost">近3个月</el-radio-button>
            <el-radio-button label="cost">近6个月</el-radio-button>
          </el-radio-group>
        </div>
        <el-table
          :data="tableData"
          :header-cell-style="rateFlowTableHeadClass"
          :row-style="{ height: '50px' }"
          style="width: 100%"
        >
          <el-table-column align="center" prop="type" label="指标">
          </el-table-column>
          <el-table-column prop="num" align="center" label="量级">
            <template #default="{ row }">
              {{ sliceDecimal(row.num) }}
            </template>
          </el-table-column>
          <el-table-column prop="growth" align="center" label="增速">
            <template #default="{ row }">
              {{ row.growth }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getBrandFlow } from "@/api/sov/sovBrand";
import flowChart from "./flowChart.vue";
export default {
  data() {
    return {
      tableData: [],
      rateFlowTableHeadClass: {
        "background-color": "#F6F8FA",
        "font-size": "14px",
        height: "50px",
      },
      exposure: "",
      exposureUv: "",
      exposureUvNum: "",
      searchUv: "",
      searchUvNum: "",
      sr: "",
      rateOfFlow: false,
      logo: "",
      chartData: {
        uv: [],
        exposure: [],
      },
      aggType: "1month",
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    id() {
      return JSON.stringify(this.$route.query) === "{}"
        ? ""
        : this.atob(this.$route.query.brandId);
    },
    timeRange() {
      if (this.$route.query.duration) {
        return JSON.parse(this.$route.query.duration);
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.getBrandFlow();
  },
  methods: {
    async getBrandFlow() {
      this.rateOfFlow = true;
      console.log(this.timeRange[0]);
      let { code, data } = await getBrandFlow({
        // duration: this.timeRange[0],
        // duration: this.timeRange,
        id: this.id,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        aggType: this.aggType,
        // aggMonth: this.timeRange[1],
      });
      if (code === 0) {
        if (data !== null) {
          this.logo = data.logo;
          console.log(this.logo);
          this.formatFlowData(data);
          if (data.platformFlowMap) {
            this.formatChartData(data.platformFlowMap);
          }
        }
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    formatChartData(data) {
      let uvArr = [];
      let exposureArr = [];
      for (let k in data) {
        if (k === "wechat") {
          uvArr[4] = data[k].uv;
          exposureArr[4] = data[k].exposure;
        } else if (k === "weibo") {
          uvArr[3] = data[k].uv;
          exposureArr[3] = data[k].exposure;
        } else if (k === "douyin") {
          uvArr[2] = data[k].uv;
          exposureArr[2] = data[k].exposure;
        } else if (k === "xiaohongshu") {
          uvArr[1] = data[k].uv;
          exposureArr[1] = data[k].exposure;
        } else if (k === "bilibili") {
          uvArr[0] = data[k].uv;
          exposureArr[0] = data[k].exposure;
        }
      }
      console.log(uvArr);
      console.log(exposureArr);
      this.chartData.uv = uvArr;
      this.chartData.exposure = exposureArr;
    },
    formatFlowData(data) {
      const tempArr = [
        {
          type: "曝光量",
          num: data.exposureNum === null ? "--" : data.exposureNum,
          growth: data.exposureGrowth === null ? "--" : data.exposureGrowth,
        },
        {
          type: "曝光UV",
          num: data.uvNum === null ? "--" : data.uvNum,
          growth: data.uvGrowth === null ? "--" : data.uvGrowth,
        },
      ];
      tempArr.forEach((v) => {
        if (v.type === "曝光量") {
          this.exposure = v.num;
          // if (v.num > 1000000) {
          //   this.exposure = (v.num / 1000000).toFixed(0) + "M";
          //   // this.exposureUv = v.num;
          // } else if (v.num > 0) {
          //   this.exposure = v.num;
          // } else {
          //   this.exposure = "--";
          // }
        } else if (v.type === "曝光UV") {
          this.exposureUvNum = v.num;
          if (v.num > 1000000000) {
            this.exposureUv = (v.num / 1000000000).toFixed(0) + "B";
          }
          if (v.num > 1000000) {
            this.exposureUv = (v.num / 1000000).toFixed(0) + "M";
            // this.exposureUv = v.num;
          } else if (v.num > 0) {
            this.exposureUv = v.num;
          } else {
            this.exposureUv = "--";
          }
        } else {
          this.searchUvNum = v.num;
          if (v.num > 1000) {
            // this.searchUv = v.num;
            this.searchUv = (v.num / 1000).toFixed(0) + "k";
          } else if (v.num > 0) {
            this.searchUv = v.num;
          } else {
            this.searchUv = "--";
          }
        }
      });
      this.sr = data.searchRate === null ? "--" : data.searchRate;
      this.draw(this.sr, this.exposureUvNum, this.searchUvNum, this.exposure);
      this.tableData = tempArr;
      this.rateOfFlow = false;
    },
    draw(sr, exposureUv, searchUv, exposure) {
      const canvas = document.getElementById("canvas");
      const context = canvas.getContext("2d");
      // let img = new Image();
      // img.src = this.logo;
      // img.setAttribute("class", "imgClass");
      // console.log(img);
      // let ready = false;
      // if (ready) {
      //   context.drawImage(img, 210, 160);
      // } else {
      //   img.onload = function () {
      //     ready = true;
      //     context.drawImage(img, 125, 125, 150, 150);
      //   };
      // }
      canvas.width = 400;
      canvas.height = 400;

      context.beginPath();
      context.lineWidth = 40;
      context.strokeStyle = "#38A1FF";
      context.arc(200, 200, 136, 0, 2 * Math.PI);
      context.stroke();
      context.closePath();
      if (exposureUv !== "--") {
        context.beginPath();
        context.lineWidth = 65;
        context.strokeStyle = "#F37092";
        context.globalAlpha = 0.8;
        context.arc(
          200,
          200,
          148,
          (2 - (exposureUv - 0) / (exposure - 0)) * Math.PI,
          (2 * ((exposureUv - 0) / (exposure - 0)) +
            (2 - (exposureUv - 0) / (exposure - 0))) *
            Math.PI
        );
        context.stroke();
        context.closePath();
      }

      if (searchUv !== "--") {
        context.beginPath();
        context.lineWidth = 68;
        context.strokeStyle = "#FEEC65";
        context.globalAlpha = 0.7;
        context.arc(
          200,
          200,
          152,
          (2 - (searchUv - 0) / (exposure - 0)) * Math.PI,
          (2 * ((searchUv - 0) / (exposure - 0)) +
            (2 - (searchUv - 0) / (exposure - 0))) *
            Math.PI
        );
        context.stroke();
        context.closePath();
      }

      context.font = '52px "微软雅黑"';
      context.textBaseline = "middle";
      context.fillStyle = "#34355B";
      context.textAlign = "center";
      // context.fillText(sr, 210, 160);
      context.fillStyle = "#939598";
      // context.fillText("SR", 180, 240);
    },
  },
  watch: {
    timeRange(val) {
      console.log(val);
      console.log("123123123123123131231231");
      if (val.length !== 0) this.getBrandFlow();
    },
    aggType(val) {
      if (val) {
        this.getBrandFlow();
      }
    },
  },
  components: {
    flowChart,
  },
};
</script>

<style lang="scss" scoped>
.publicStyle {
  padding-bottom: 10px;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .publicTitleStyle {
    padding-top: 20px;
    padding-left: 20px;
    font-size: 20px;
    color: #4f4f4f;
  }

  .content {
    display: flex;
    align-items: center;

    .leftbox {
      position: relative;
      flex: 0.9;
      display: flex;
      align-items: center;

      .showNum {
        margin-left: 65px;

        .wordClass {
          font-size: 30px;
          color: #3648a4;
          margin-left: 10px;
        }

        .textClass {
          color: #3648a4;
          font-size: 14px;
          margin-left: 48px;
        }
      }

      .hover {
        position: absolute;
        font-size: 24px;
        top: 108px;
        left: 114px;
      }
    }
    .chartClass {
      flex: 0.8;
    }
    .tableClass {
      flex: 0.8;
      position: relative;
      .radioGroupClass {
        position: absolute;
      }
    }
  }
}
</style>

<style lang="scss">
.imgWrapper {
  position: relative;
  .imgClass {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 150px;
    height: 150px;
  }
}
.monthRadioButton {
  //.is-active {
  //  .el-radio-button__inner {
  //    background: linear-gradient(180deg, #daae3c 0%, #e09524 100%) !important;
  //    border-color: #e09a29 !important;
  //    color: #fff !important;
  //  }
  //}
  //
  //.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  //  box-shadow: -1px 0 0 0 #e09a29;
  //}
  //
  //.el-radio-button {
  //  .el-radio-button__inner {
  //    background-color: #f8f9fe;
  //    color: #979797;
  //  }
  //}
}
</style>
