<template>
  <div class="publicStyle structure">
    <div>
      <p class="publicTitleStyle">品牌合作KOL结构
        <div style="display: flex;justify-content: flex-end;align-items: center;gap:20px;">
          <el-radio-group
              v-model="field"
              size="mini"
              :class="{ cancelMargin: !isShowCategory }"
          >
            <el-radio-button label="pgc_num">发帖数量</el-radio-button>
            <el-radio-button label="cost">投放费用</el-radio-button>
          </el-radio-group>
          <el-radio-group v-model="type" >
            <el-radio label="percent">占比</el-radio>
            <el-radio label="amount">数量</el-radio>
          </el-radio-group>
        </div>
      </p>

    </div>

    <el-table
      v-loading="structureLoading"
      :header-cell-style="generalViewTableHeadClass"
      :data="structureTableData"
      style="width: 100%; margin-bottom: 20px"
    >
      <el-table-column align="center" prop="name" label="平台" width="200">
        <template #default="{ row }">
          <span
            class="spans"
            v-if="row.name.includes('Tier') || row.name.includes('KOC')"
          >
            <i
              @click="showTierMethod"
              class="el-icon-warning-outline iStype"
            ></i>
            {{ row.name }}
          </span>
          <span v-else>{{ row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="wechat" label="微信">
        <template #default="{ row }">
          {{
            type === "percent"
              ? row.wechat === null
                ? "--"
                : row.wechat
              : row.wechat === "--"
              ? 0
              : field === "cost"
              ? "￥" + sliceDecimal(row.wechat)
              : sliceDecimal(row.wechat)
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="weibo" label="微博">
        <template #default="{ row }">
          {{
            type === "percent"
              ? row.weibo === null
                ? "--"
                : row.weibo
              : row.weibo === "--"
              ? 0
              : field === "cost"
              ? "￥" + sliceDecimal(row.weibo)
              : sliceDecimal(row.weibo)
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="douyin" label="抖音">
        <template #default="{ row }">
          {{
            type === "percent"
              ? row.douyin === null
                ? "--"
                : row.douyin
              : row.douyin === "--"
              ? 0
              : field === "cost"
              ? "￥" + sliceDecimal(row.douyin)
              : sliceDecimal(row.douyin)
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="xiaohongshu" label="小红书">
        <template #default="{ row }">
          {{
            type === "percent"
              ? row.xiaohongshu === null
                ? "--"
                : row.xiaohongshu
              : row.xiaohongshu === "--"
              ? 0
              : field === "cost"
              ? "￥" + sliceDecimal(row.xiaohongshu)
              : sliceDecimal(row.xiaohongshu)
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="bilibili" label="哔哩哔哩">
        <template #default="{ row }">
          {{
            type === "percent"
              ? row.bilibili === null
                ? "--"
                : row.bilibili
              : row.bilibili === "--"
              ? 0
              : field === "cost"
              ? "￥" + sliceDecimal(row.bilibili)
              : sliceDecimal(row.bilibili)
          }}
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-model="showTier" width="50%" append-to-body>
      <img
        style="width: 100%; height: 100%"
        class="imgClass"
        :src="require('./tier.png')"
        alt=""
      />
    </el-dialog>
  </div>
</template>

<script>
import { getBrandAdStructure } from "@/api/sov/sovBrand";

export default {
  data() {
    return {
      type: "percent",
      structureTableData: [],
      structureLoading: true,
      generalViewTableHeadClass: {
        "background-color": "#F6F8FA",
        "font-size": "14px",
      },
      showTier: false,
      field: "pgc_num",
    };
  },
  mounted() {
    this.getBrandAdStructure();
  },
  methods: {
    showTierMethod() {
      this.showTier = true;
    },
    async getBrandAdStructure() {
      this.structureLoading = true;
      let { code, data } = await getBrandAdStructure({
        id: this.id,
        // duration: this.timeRange[0],
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        type: this.type,
        field: this.field,
        // aggMonth: this.timeRange[1],
      });
      // 设置name
      if (data.length !== 0) {
        if (this.type === "percent") {
          if (this.field === "pgc_num") {
            data[0].name = "各平台KOL投放数占比";
          } else {
            data[0].name = "各平台KOL投放费用占比";
          }
        } else {
          if (this.field === "pgc_num") {
            data[0].name = "各平台KOL投放数";
          } else {
            data[0].name = "各平台KOL投放费用";
          }
        }
      }

      if (code === 0) {
        this.formatNull(data);
        this.structureTableData = data;
        this.structureLoading = false;
      } else {
        this.$message.error("获取数据失败！");
        this.structureLoading = false;
      }
    },
  },
  computed: {
    timeRange() {
      if (this.$route.query.duration) {
        return JSON.parse(this.$route.query.duration);
      } else {
        return [];
      }
    },
    id() {
      console.log(this.$route.query);
      return JSON.stringify(this.$route.query) === "{}"
        ? ""
        : this.atob(this.$route.query.brandId);
    },
  },
  watch: {
    timeRange(val) {
      if (val.length !== 0) this.getBrandAdStructure();
    },
    type(val) {
      if (val) this.getBrandAdStructure();
    },
    field(val) {
      if (val) this.getBrandAdStructure();
    },
  },
};
</script>

<style lang="scss">
.structure {
  margin-top: 10px;
  .firstRowStyle {
    td div {
      font-size: 18px;
      color: #393a4d;
      font-weight: 600;
    }
  }

  .otherStyle {
    td div {
      font-size: 14px;
      color: #34355b;
      font-weight: 400;
    }
  }
  //.el-radio__input.is-checked + .el-radio__label {
  //  color: #e09a29 !important;
  //}
  //.el-radio__input.is-checked .el-radio__inner {
  //  color: #e09a29 !important;
  //  border-color: #e09a29 !important;
  //  background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
  //}
  //.imgClass {
  //  width: 100%;
  //  height: 100%;
  //  background-size: contain;
  //}
  //.is-active {
  //  .el-radio-button__inner {
  //    background: linear-gradient(180deg, #daae3c 0%, #e09524 100%) !important;
  //    border-color: #e09a29 !important;
  //    color: #fff !important;
  //  }
  //}
  //
  //.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  //  box-shadow: -1px 0 0 0 #e09a29;
  //}
  //
  //.el-radio-button {
  //  .el-radio-button__inner {
  //    background-color: #f8f9fe;
  //    color: #979797;
  //  }
  //}
  // .el-radio__input.is-checked .el-radio__inner {
  //   background-color: #fff;
  //   border-color: #dda634;
  // }

  // .el-radio__input.is-checked + .el-radio__label {
  //   color: #dda634;
  // }

  // .el-radio__inner:hover {
  //   border-color: #dda634;
  // }
}
</style>

<style lang="scss" scoped>
.publicStyle {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 0px 10px;

  .publicTitleStyle {
    padding:20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    color: #4f4f4f;
  }

  .downBtn {
    background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
    border-radius: 4px;
    color: #fff;
    padding: 1px 9px;

    .downIconClass {
      font-size: 16px;
      margin-left: 10px;
    }
  }
  .iStype {
    cursor: pointer;
  }
}
</style>
