<template>
  <div class="filterBlock">
    <div class="filterBoxFixed">
      <sovTitle cardTitle="时间范围"></sovTitle>
    </div>
    <div class="filterRightBlock">
      <sovBrandRateOfFlow
          style="margin-top:0;"
          title="品牌流量池"
      ></sovBrandRateOfFlow>
      <sovGeneralView title="品牌声量概览" headerWordType="0"></sovGeneralView>
      <brandPlatformTa
          @changeSegValue="changeSegValue"
          title="投放TA触达效率"
          headerWordType="0"
      ></brandPlatformTa>
      <!-- <sovDistribute title="品牌声量分布" headerWordType="0"></sovDistribute> -->
      <sovBrandStructure></sovBrandStructure>
      <sovChartCart
          title="品牌表现趋势"
          @seeBrandChart="seeBrandChart"
          @seeTotalChart="seeTotalChart"
          @timeSel="timeSel"
          @timeSelCate="timeSelCate"
          @cateFilter="cateFilter"
          @setYtype="setYtype"
          :tempCampaignFilter="tempCampaignFilter"
          :campaignList="cateList"
          :echartLegend="echartLegend"
          :chartData="chartData"
      >
      </sovChartCart>
      <!-- <sovChartCart
        title="产品声量趋势"
        :isProduct="true"
        :isShowCategory="false"
        @productFilter="productFilter"
        @proTimeSel="proTimeSel"
        :tempCampaignFilter="tempBrandCampaignFilter"
        :echartLegend="productLegend"
        :campaignList="productList"
        :chartData="chartDataProduct"
      >
      </sovChartCart> -->
      <el-card style="margin-bottom: 21px; border-radius: 10px">
        <h2
            style="font-size: 20px; margin-bottom: 20px; color: #4f4f4f"
            class="insight_title"
        >
          品牌社媒人群画像
        </h2>
        <div
            style="display: flex; justify-content: space-between; flex-wrap: wrap"
        >
          <stagger-x-bar
              style="width: 48%; height: 260px; margin-bottom: 50px"
              v-for="(item, i) in profileData"
              :ref="'staggerXBar' + i"
              :key="item.title"
              :title="item.title"
              :xAxisData="item.xAxisData"
              :yAxisData="item.yAxisData"
              :seriesData="item.seriesData"
              :color="
            item.title === '性别占比'
              ? ['rgb(83, 186, 210)', '#e893a8']
              : item.title === '城市级别占比'
              ? ['#32a3be', 'rgb(83, 186, 210)', '#e893a8', '#e37893']
              : [
                  '#2c7d98',
                  '#32a3be',
                  'rgb(83, 186, 210)',
                  '#d1eef8',
                  '#f0a3b6',
                  '#e37893',
                  '#a02947',
                ]
          "
          />
        </div>
      </el-card>
      <sovKolContent
          title="品牌PGC投放KOL内容"
          @setSelPlatform="setSelPlatform"
          @handleSearch="handleSearch"
          @searchBtn="searchBtn"
          @downContent="downContent"
          @loadMoreData="loadMoreData"
          :loading="brandArticleTopLoading"
          :brandTab="brand"
          :tableData="kolContentTableDataCopy"
          :fileDownFlag="fileDownFlag"
      >
      </sovKolContent>
    </div>

  </div>
</template>

<script>
import sovTitle from "../sovCommonComponents/sovTitle";
import sovGeneralView from "./sovComponents/sovGeneralView";
import brandPlatformTa from "./sovComponents/brandPlatformTa.vue";
// import sovDistribute from "../sovCommonComponents/sovDistribute";
import sovChartCart from "./sovComponents/sovChartCart";
import sovKolContent from "./sovComponents/sovKolContent";
import sovBrandStructure from "./sovComponents/sovBrandStructure";
import sovBrandRateOfFlow from "./sovComponents/sovBrandRateOfFlow";
import staggerXBar from "@/components/echarts/staggerXBar";
import {
  getBrandCateTrend,
  getBrandArticleTop,
  getBrandComparisonTrend,
  getBrandProductTrend,
  getBrandMonth,
  getCampaignInsightProfile,
  downloadTopReport,
} from "@/api/sov/sovBrand";
import {
  setStartTime,
  setEndTime,
  setStartWeekTime,
  setEndWeekTime,
  setStartMonthTime,
  setEndMonthTime,
} from "../sovCommonFunc/index";
import { mapState } from "vuex";

export default {
  data() {
    return {
      sovGeneralViewSeg: "32_1",
      segValue: "32_1",
      timeRange: "",
      chartData: {
        echartxAxis: {
          data: [],
          type: "category",
        },
        echartSeries: [
          {
            data: [],
            name: "",
            type: "line",
            smooth: true,
          },
        ],
        echartColor: ["rgb(83, 186, 210)", "#32a3be", "#daae3c"],
      },
      chartDataProduct: {
        echartxAxis: {
          data: [],
          type: "category",
        },
        echartSeries: [
          {
            data: [],
            name: "",
            type: "line",
            smooth: true,
          },
        ],
        echartColor: ["rgb(83, 186, 210)", "#32a3be", "#daae3c"],
      },
      defaultColors: [
        "#2c7d98",
        "#d1eef8",
        "#f0a3b6",
        "#a02947",
        "#06c1ff",
        "#65aeb1",
        "#bda29a",
        "#ff7c7c",
        "#ffd3d3",
        "#db7cae",
        "#d3baed",
        "#966767",
        "#4fe3ff",
        "#ffa86d",
        "#6797fe",
        "#6c80fe",
        "#ffb57e",
        "#4ebcb4",
      ],
      kolContentTableData: [
        {
          brand: "巴黎欧莱雅",
          product: "黑绷带",
          kolName: "小蛮蛮小",
          platform: "微博",
          fans: "2930922",
          date: "2021/12/30",
          url: "www.baidu.com",
          uv: "2929299292",
          engagement: "250000",
        },
        {
          brand: "巴黎欧莱雅",
          product: "黑绷带",
          kolName: "小蛮蛮小",
          platform: "微博",
          fans: "2930922",
          date: "2021/12/30",
          url: "www.baidu.com",
          uv: "2929299292",
          engagement: "250000",
        },
        {
          brand: "巴黎欧莱雅",
          product: "黑绷带",
          kolName: "小蛮蛮小",
          platform: "微博",
          fans: "2930922",
          date: "2021/12/30",
          url: "www.baidu.com",
          uv: "2929299292",
          engagement: "250000",
        },
      ],
      searchInput: "",
      selPlatform: "all",
      echartsTime: "day",
      productEchartsTime: "day",
      catefilterOriginDate: [],
      originCateData: [],
      echartLegend: [],
      echartProductLegend: [],
      cateList: [],
      productList: [],
      originLegend: [],
      originSeries: [],
      productOriginLegend: [],
      productLegend: [],
      productOriginSeries: [],
      brandArticleTopLoading: false,
      tempCateFilter: [],
      tempBrandFilter: [],
      tempCampaignFilter: [],
      tempBrandCampaignFilter: [],
      timeMonth: "",
      timeYear: "",
      profileData: [],
      page: 1,
      size: 20,
      total: 0,
      fileDownFlag: false,
      yType: "exposure",
      kolContentTableDataCopy: [],
      articleTopSeg: "32_1",
    };
  },
  props: {
    brand: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    duration: {
      type: String,
      default: "",
    },
  },
  mounted() {
    console.log(this.duration);
    this.timeRange = JSON.parse(this.duration);
    window.addEventListener("scroll", this.handleScroll); //监听滚动事件
  },
  methods: {
    changeSegValue(val) {
      this.sovGeneralViewSeg = val;
      this.articleTopSeg = val;
      this.getBrandArticleTop();
    },
    async initData() {
      await this.getBrandMonth();
      this.getBrandArticleTop();
      this.seeTotalChart();
      this.getProfile();
    },
    handleScroll() {
      console.log(2);
    },
    // 人群画像
    async getProfile() {
      const { data } = await getCampaignInsightProfile({
        id: this.id,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        // duration: this.timeRange[0],
        // aggMonth: this.timeRange[1],
      });
      const yAxisData = [
        "wechat",
        "weibo",
        "xiaohongshu",
        "douyin",
        "bilibili",
      ];
      this.profileData = data.map((item) => {
        const arr = [];
        const temp = {
          yAxisData,
        };
        switch (item.name) {
          case "age":
            temp.title = "年龄占比";
            break;
          case "gender":
            temp.title = "性别占比";
            break;
          case "income":
            temp.title = "收入水平占比";
            break;
          case "city_tier":
            temp.title = "城市级别占比";
            break;
        }
        temp.xAxisData = item.profile.map((temp) => temp.label);
        const keyArr = item.profile.map((temp) => temp.id);
        yAxisData.forEach((platform) => {
          const temp = [];
          keyArr.forEach((key) => {
            // temp.push(
            //   item.proportionObj[platform] && item.proportionObj[platform][key]
            //     ? Math.floor(item.proportionObj[platform][key] * 100)
            //     : null
            // );
            if (item.proportionMap !== null) {
              temp.push(
                item.proportionMap[platform] &&
                  item.proportionMap[platform][key]
                  ? Math.floor(item.proportionMap[platform][key] * 100)
                  : null
              );
            } else {
              temp.push(null);
            }
          });
          const index = temp.findIndex((item) => item != null);
          if (index !== -1) {
            // 更改temp的最后一个值以保证temp每项相加和为100
            let endValue = 100;
            temp.slice(0, -1).forEach((item) => {
              endValue = endValue - item;
            });
            temp[temp.length - 1] = endValue <= 0 ? 0 : endValue;
          }
          arr.push(temp);
        });
        console.log(arr[0]);
        // 转置
        temp.seriesData = arr[0].map((col, i) => arr.map((row) => row[i]));
        return temp;
      });
    },
    async getBrandMonth() {
      let { code, data } = await getBrandMonth();
      if (code === 0) {
        this.timeMonth = data.split("-")[1];
        this.timeYear = data.split("-")[0];
      } else {
        this.$message.error("获取时间失败，请联系管理员!");
      }
    },
    async getBrandProductTrend() {
      let { code, data } = await getBrandProductTrend({
        duration: this.timeRange,
        id: this.id,
        xType: this.productEchartsTime,
        yType: this.yType,
      });
      if (code === 0) {
        if (data.length !== 0) {
          this.productList = this.getProductList(data);
        }
        this.productFilterOriginDate = [].concat(data);
        this.initProductTrendData(data);
        this.productOriginLegend = this.echartProductLegend;
        this.productLegend = this.productOriginLegend;
        this.productOriginSeries = this.chartDataProduct.echartSeries;
        if (this.tempBrandFilter.length === 0) {
          this.productFilter(this.productList);
        } else {
          this.productFilter(this.tempBrandFilter);
        }
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    getProductList(data) {
      let tempArr = [];
      data.forEach((v) => {
        tempArr.push(v.name);
      });
      return tempArr;
    },
    async getBrandCateTrend() {
      let res = await getBrandCateTrend({
        // duration: this.timeRange[0],
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.id,
        xType: this.echartsTime,
        yType: this.yType,
        // aggMonth: this.timeRange[1],
      });
      if (res.code === 0) {
        this.cateList = this.getCateList(res.data);
        this.catefilterOriginDate = [].concat(res.data);
        this.originCateData = [].concat(res.data);
        this.initCateTrendData(res.data);
        this.originLegend = this.echartLegend;
        this.originSeries = this.chartData.echartSeries;
        if (this.tempCateFilter.length === 0) {
          this.cateFilter(this.cateList);
        } else {
          this.cateFilter(this.tempCateFilter);
        }
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    setYtype(val) {
      this.yType = val.yType;
      if (val.isShowCate) {
        this.getBrandComparisonTrend();
      } else {
        this.getBrandCateTrend();
      }
    },
    cateFilter(cateFilter) {
      this.tempCateFilter = cateFilter;
      this.tempCampaignFilter = cateFilter;
      const cateSeriesTemp = [];
      const cateFilterTemp = [];
      this.originLegend.forEach((k) => {
        cateFilter.forEach((v) => {
          if (k.name === v) {
            cateFilterTemp.push(k);
          }
        });
      });
      cateFilterTemp.forEach((v, i) => {
        v.color = this.chartData.echartColor.concat(this.defaultColors)[i];
      });
      this.echartLegend = cateFilterTemp;
      this.originSeries.forEach((series) => {
        cateFilter.forEach((v) => {
          if (series.name === v) {
            cateSeriesTemp.push(series);
          }
        });
      });
      // 防止echarts缓存复用问题
      this.chartData.echartSeries = [];
      this.chartData.echartSeries = cateSeriesTemp;
    },
    productFilter(productFilter) {
      const productTemp = [];
      const productSeries = [];
      this.tempBrandFilter = productFilter;
      this.tempBrandCampaignFilter = productFilter;
      this.productOriginLegend.forEach((k) => {
        productFilter.forEach((v) => {
          if (k.name === v) {
            productTemp.push(k);
          }
        });
      });
      // 重新设置颜色
      productTemp.forEach((v, i) => {
        v.color = this.chartDataProduct.echartColor.concat(this.defaultColors)[
          i
        ];
      });
      this.productLegend = productTemp;
      this.productOriginSeries.forEach((series) => {
        productFilter.forEach((v) => {
          if (series.name === v) {
            productSeries.push(series);
          }
        });
      });
      this.chartDataProduct.echartSeries = productSeries;
    },
    getCateList(data) {
      let tempArr = [];
      data.forEach((v) => {
        v.cateInfoList.forEach((cate) => {
          tempArr.push(cate.cateName);
        });
      });
      return tempArr;
    },
    initProductTrendData(data) {
      this.echartProductLegend = [];
      const startTimeArr = [];
      const endTimeArr = [];
      data.forEach((campaign, i) => {
        this.echartProductLegend.push({
          name: campaign.name,
          color: this.chartDataProduct.echartColor.concat(this.defaultColors)[
            i
          ],
          index: Math.random() * 2 * Math.random(),
        });
        startTimeArr.push(campaign.trend[0].xAxis);
        const lastIndex = campaign.trend.length - 1;
        endTimeArr.push(campaign.trend[lastIndex].xAxis);
      });
      if (this.productEchartsTime === "day") {
        // day
        startTimeArr.push(
          setStartTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatDayDate(startTimeArr, endTimeArr, "product");
      } else if (this.productEchartsTime === "week") {
        // week
        startTimeArr.push(
          setStartWeekTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndWeekTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatWeekDate(startTimeArr, endTimeArr, "product");
      } else {
        // month
        startTimeArr.push(
          setStartMonthTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndMonthTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatMonthDate(startTimeArr, endTimeArr, "product");
      }
    },
    initTrendData(data) {
      this.echartLegend = [];
      const startTimeArr = [];
      const endTimeArr = [];
      data.forEach((campaign, i) => {
        this.echartLegend.push({
          name: campaign.name,
          color: this.chartData.echartColor.concat(this.defaultColors)[i],
          index: Math.random() * 2 * Math.random(),
        });
        startTimeArr.push(campaign.trend[0].xAxis);
        const lastIndex = campaign.trend.length - 1;
        endTimeArr.push(campaign.trend[lastIndex].xAxis);
      });
      if (this.echartsTime === "day") {
        // day
        console.log(this.timeRange);
        startTimeArr.push(
          setStartTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatDayDate(startTimeArr, endTimeArr);
      } else if (this.echartsTime === "week") {
        // week
        startTimeArr.push(
          setStartWeekTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndWeekTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatWeekDate(startTimeArr, endTimeArr);
      } else {
        // month
        startTimeArr.push(
          setStartMonthTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndMonthTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        console.log(startTimeArr, endTimeArr);
        this.formatMonthDate(startTimeArr, endTimeArr);
      }
    },
    initCateTrendData(data) {
      this.echartLegend = [];
      const startTimeArr = [];
      const endTimeArr = [];
      data.forEach((campaign) => {
        campaign.cateInfoList.forEach((v, i) => {
          this.echartLegend.push({
            name: v.cateName,
            color: this.chartData.echartColor.concat(this.defaultColors)[i],
            index: Math.random() * 2 * Math.random(),
          });
          startTimeArr.push(v.trend[0].xAxis);
          const lastIndex = v.trend.length - 1;
          endTimeArr.push(v.trend[lastIndex].xAxis);
        });
      });
      if (this.echartsTime === "day") {
        // day
        startTimeArr.push(
          setStartTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatDayDate(startTimeArr, endTimeArr, "cate");
      } else if (this.echartsTime === "week") {
        // week
        startTimeArr.push(
          setStartWeekTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndWeekTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatWeekDate(startTimeArr, endTimeArr, "cate");
      } else {
        // month
        startTimeArr.push(
          setStartMonthTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndMonthTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatMonthDate(startTimeArr, endTimeArr, "cate");
      }
    },
    // consoel.log(123)
    // 时间问题 开始时间ok 结束时间有问题
    formatDayDate(startTimeArr, endTimeArr, type) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getDayAllDate(startTime, endTime, type);
    },
    getDayAllDate(start, end, type) {
      let dateArr = [];
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      let stamp = stampDb;
      const oneDay = 24 * 60 * 60 * 1000;
      for (stamp; stamp <= stampDe; stamp += oneDay) {
        dateArr.push(this.timeStampToDate(stamp));
      }
      this.chartData.echartxAxis.data = dateArr;
      if (type === "product") {
        this.chartDataProduct.echartxAxis.data = dateArr;
      }
      if (type === "cate") {
        this.getCateDataFromOrigin();
      } else if (type === "product") {
        this.getProductDataFromOrigin();
      } else {
        this.getDataFromOrigin();
      }
    },
    formatWeekDate(startTimeArr, endTimeArr, type) {
      const nStartTimeArr = startTimeArr.map((time) => time.slice(0, 10));
      const nEndTimeArr = endTimeArr.map((time) => time.slice(-10));
      const startTimeStamp = nStartTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = nEndTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getWeekAllDate(startTime, endTime, type);
    },
    getWeekAllDate(start, end, type) {
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      let stamp = stampDb;
      const oneWeek = 7 * 24 * 60 * 60 * 1000;
      let stampArr = [];
      for (stamp; stamp <= stampDe; stamp += oneWeek) {
        stampArr.push(stamp);
      }
      const sixDay = 6 * 24 * 60 * 60 * 1000;
      let dateArr = [];
      dateArr = stampArr.map((stamp) => {
        const weekLastDayStamp = stamp + sixDay;
        return (
          this.timeStampToDate(stamp) +
          " ~ " +
          this.timeStampToDate(weekLastDayStamp)
        );
      });
      this.chartData.echartxAxis.data = dateArr;
      if (type === "product") {
        this.chartDataProduct.echartxAxis.data = dateArr;
      }
      if (type === "cate") {
        this.getCateDataFromOrigin();
      } else if (type === "product") {
        this.getProductDataFromOrigin();
      } else {
        this.getDataFromOrigin();
      }
    },
    formatMonthDate(startTimeArr, endTimeArr, type) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getMonthAllDate(startTime, endTime, type);
    },
    getMonthAllDate(start, end, type) {
      let startArr = start.split("-");
      let startYear = startArr[0] * 1;
      let startMonth = startArr[1] * 1;
      let endArr = end.split("-");
      let endYear = endArr[0] * 1;
      let endMonth = endArr[1] * 1;
      let dateArr = [];
      if (startYear < endYear) {
        for (startMonth; startMonth <= 12; startMonth++) {
          const tempStr =
            startYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
          if (startMonth === 12 && startYear + 1 < endYear) {
            startYear++;
            startMonth = 0;
          }
        }
        for (let i = 1; i <= endMonth; i++) {
          const tempStr = endYear + "-" + (i < 10 ? "0" + i : i);
          dateArr.push(tempStr);
        }
      } else {
        for (startMonth; startMonth <= endMonth; startMonth++) {
          const tempStr =
            endYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
        }
      }
      this.chartData.echartxAxis.data = dateArr;
      if (type === "product") {
        this.chartDataProduct.echartxAxis.data = dateArr;
      }
      if (type === "cate") {
        this.getCateDataFromOrigin();
      } else if (type === "product") {
        this.getProductDataFromOrigin();
      } else {
        this.getDataFromOrigin();
      }
    },
    timeStampToDate(timestamp) {
      // 1614556800000
      // 1612198020199
      const date = new Date(timestamp);
      const Y = date.getFullYear();
      const M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return Y + "-" + M + "-" + D;
    },
    timeSel(val) {
      this.echartsTime = val;
      this.getBrandCateTrend();
    },
    proTimeSel(val) {
      this.productEchartsTime = val;
      // this.getBrandProductTrend();
    },
    timeSelCate(val) {
      this.echartsTime = val;
      this.getBrandComparisonTrend();
    },
    seeTotalChart() {
      this.cateList = [this.brand];
      this.tempCampaignFilter = this.cateList;
      this.getBrandComparisonTrend();
    },
    seeBrandChart() {
      this.tempCateFilter = [];
      this.getBrandCateTrend();
    },
    async getBrandComparisonTrend() {
      console.log(this.timeRange[0], this.timeRange[1], this.timeRange);
      let res = await getBrandComparisonTrend({
        // duration: this.timeRange[0],
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.id,
        xType: this.echartsTime,
        yType: this.yType,
        // aggMonth: this.timeRange[1],
      });
      if (res.code === 0) {
        this.originData = [].concat(res.data);
        this.filterOriginDate = [].concat(res.data);
        this.initTrendData(res.data);
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    getDataFromOrigin() {
      this.chartData.echartSeries = this.originData.map((campaign) => {
        const seriesItem = {
          data: [],
          name: "",
          type: "line",
          smooth: true,
        };
        seriesItem.name = campaign.name;
        // 重写逻辑：从整个时间轴上截取时间段，段内补0，段外补null
        const startTimeIndex = this.chartData.echartxAxis.data.indexOf(
          campaign.trend[0].xAxis
        );
        const endTimeIndex = this.chartData.echartxAxis.data.indexOf(
          campaign.trend[campaign.trend.length - 1].xAxis
        );
        const campaignxAxis = this.chartData.echartxAxis.data.slice(
          startTimeIndex,
          endTimeIndex + 1
        );
        seriesItem.data = this.chartData.echartxAxis.data.map((xAxis) => {
          let data = 0;
          if (campaignxAxis.includes(xAxis)) {
            const trend = campaign.trend.find((item) => item.xAxis === xAxis);
            trend ? (data = trend.yAxis) : (data = 0);
          }
          return data;
        });
        return seriesItem;
      });
    },
    getCateDataFromOrigin() {
      let res = [];
      this.echartSeries = [];
      this.catefilterOriginDate.forEach((campaign) => {
        campaign.cateInfoList.forEach((v) => {
          const seriesItem = {
            data: [],
            name: "",
            type: "line",
            smooth: true,
          };
          seriesItem.name = v.cateName;
          // 重写逻辑：从整个时间轴上截取时间段，段内补0，段外补null
          const startTimeIndex = this.chartData.echartxAxis.data.indexOf(
            v.trend[0].xAxis
          );
          const endTimeIndex = this.chartData.echartxAxis.data.indexOf(
            v.trend[v.trend.length - 1].xAxis
          );
          const campaignxAxis = this.chartData.echartxAxis.data.slice(
            startTimeIndex,
            endTimeIndex + 1
          );

          seriesItem.data = this.chartData.echartxAxis.data.map((xAxis) => {
            let data = 0;
            if (campaignxAxis.includes(xAxis)) {
              const trend = v.trend.find((item) => item.xAxis === xAxis);
              trend ? (data = trend.yAxis) : (data = 0);
            }
            return data;
          });
          res.push(seriesItem);
        });
      });
      this.chartData.echartSeries = res;
    },
    getProductDataFromOrigin() {
      this.chartDataProduct.echartSeries = this.productFilterOriginDate.map(
        (campaign) => {
          const seriesItem = {
            data: [],
            name: "",
            type: "line",
            smooth: true,
          };
          seriesItem.name = campaign.name;
          // 重写逻辑：从整个时间轴上截取时间段，段内补0，段外补null
          const startTimeIndex = this.chartDataProduct.echartxAxis.data.indexOf(
            campaign.trend[0].xAxis
          );
          const endTimeIndex = this.chartDataProduct.echartxAxis.data.indexOf(
            campaign.trend[campaign.trend.length - 1].xAxis
          );
          const campaignxAxis = this.chartDataProduct.echartxAxis.data.slice(
            startTimeIndex,
            endTimeIndex + 1
          );
          seriesItem.data = this.chartDataProduct.echartxAxis.data.map(
            (xAxis) => {
              let data = 0;
              if (campaignxAxis.includes(xAxis)) {
                const trend = campaign.trend.find(
                  (item) => item.xAxis === xAxis
                );
                trend ? (data = trend.yAxis) : (data = 0);
              }
              return data;
            }
          );
          return seriesItem;
        }
      );
    },
    async getBrandArticleTop() {
      this.brandArticleTopLoading = true;
      let res = await getBrandArticleTop({
        // duration: this.timeRange[0],
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.id,
        searchWord: this.searchInput,
        platform: this.selPlatform,
        sortColumn: "exposure",
        sortDirection: "desc",
        size: 20,
        page: this.page,
        seg: this.brandSeg,
        // aggMonth: this.timeRange[1],
      });
      if (res.code === 0) {
        this.total = res.data.total;
        this.kolContentTableData = res.data.adsTopArticles;
        this.kolContentTableDataCopy.push(...this.kolContentTableData);
        this.brandArticleTopLoading = false;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    handleSearch(searchInput) {
      this.searchBtn(searchInput);
    },
    searchBtn(searchInput) {
      this.kolContentTableDataCopy = [];
      this.page = 1;
      this.searchInput = searchInput;
      this.getBrandArticleTop();
    },
    loadMoreData() {
      if (this.page * this.size > this.total) {
        this.$message({
          type: "info",
          message: "没有更多数据了!",
        });
        return;
      }
      this.page++;
      this.getBrandArticleTop(true);
    },
    downContent(data) {
      downloadTopReport(
        {
          // duration: this.timeRange[0],
          // duration: this.timeRange,
          startMonth: this.timeRange[0],
          endMonth: this.timeRange[1],
          id: this.id,
          platform: data.platform,
          searchWord: data.searchWord,
          seg: this.brandSeg,
          // aggMonth: this.timeRange[1],
        },
        `${decodeURI(this.$route.query.brand)}_KOL投放内容.xlsx`
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "文件正在下载",
          });
          this.fileDownFlag = true;
        })
        .catch(() => {
          this.$message.error("文件下载失败,请联系管理员!");
        });
    },
    setSelPlatform(platform) {
      this.selPlatform = platform;
      this.kolContentTableDataCopy = [];
      this.page = 1;
      this.getBrandArticleTop();
    },
  },
  watch: {
    timeRange(val) {
      console.log(val);
      this.initData();
    },
    brandSeg() {
      this.kolContentTableDataCopy = [];
      this.getBrandArticleTop();
    },
  },
  computed: {
    ...mapState(["brandSeg"]),
  },
  components: {
    sovTitle,
    sovGeneralView,
    brandPlatformTa,
    // sovDistribute,
    staggerXBar,
    sovBrandStructure,
    sovChartCart,
    sovKolContent,
    sovBrandRateOfFlow,
  },
};
</script>

<style lang="scss" scoped>
.filterBlock{
  display: flex;
  justify-content: space-between;
  gap:20px;
}
.filterRightBlock{
  width:80%;
}
.filterBoxFixed {
  width:20%;
  position: sticky;
  top:0;
  height: 100%;
}
.container {
  font-size: 16px;
  position: relative;
  top: -10px;
  background-color: #fff;
  height: 62px;
  line-height: 60px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  padding: 0 20px;
  color: #11263c;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .sovTitle {
    overflow: hidden;

    .filter {
      width: 70%;
      float: left;
      display: flex;
      justify-content: flex-start;

      .brandText {
        line-height: 59px;
        color: #11263c;

        span {
          padding: 0 10px;
          border-right: 1px solid #d4d0db;
        }

        & :first-child {
          padding-left: 0;
        }

        & :last-child {
          padding-right: 0;
          border-right: none;
        }
      }
    }

    .btn {
      float: right;

      .returnBtn {
        color: #fff;
        width: 74px;
        height: 28px;
        margin-top: 17px;
        line-height: 25px;
        padding: 0;
        background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
        border-radius: 4px;
      }
    }
  }
}
</style>
